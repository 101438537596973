import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/src/layout/Default.js";
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const DoDontRow = makeShortcode("DoDontRow");
const DoDont = makeShortcode("DoDont");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h2>{`Anatomy`}</h2>
    <p>{`The Navigation Bar component, also known as a Navbar, is a user interface element that provides a menu of navigation links for users to access different sections of a website or application.`}</p>
    <img {...{
      "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/navbar/guideline-navbar-anatomy.svg",
      "alt": "anatomy navbar"
    }}></img>
    <ol>
      <li parentName="ol"><strong parentName="li">{`Brand/logo`}</strong>{`: The visual element that identifies the website or application.`}</li>
      <li parentName="ol"><strong parentName="li">{`Navigation links`}</strong>{`: The links that navigate to different sections or pages of the website or application.`}</li>
      <li parentName="ol"><strong parentName="li">{`Dropdown menus`}</strong>{`: Additional menus that provide access to sub-navigation links or additional functionality.`}</li>
    </ol>
    <div className="divi" />
    <h2>{`Usage`}</h2>
    <p>{`The Navigation Bar component is used to provide users with easy access to different sections or pages of a website or application. It is commonly used in web applications, online stores, and other contexts where users need to navigate through a large amount of content.`}</p>
    <p><strong parentName="p">{`Common places buttons appear`}</strong>{`: Mobile app homepage , website homepage and other pages when needed etc.`}</p>
    <div className="row1">
  <div className="double-column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#429777"
        }}>
      When to use
    </p>
    <ul>
      <li>When you have multiple sections or pages that need to be accessed by users.</li>
      <li>When you want to provide a clear and consistent navigation system for users.</li>
      <li>When you want to improve the usability and user experience of your website or application.</li>
    </ul>
  </div>
  <div className="column1 mb-2" style={{
        "justifyContent": "flex-start",
        "flex": "1"
      }}>
    <p className="text-xl font-bold mb-2" style={{
          "color": "#e42313"
        }}>
      When not to use
    </p>
    <ul>
      <li>When there are only a few pages or sections that can be accessed through the website or application.</li>
      <li>When the Navigation Bar would add unnecessary complexity or clutter to the interface.</li>
      <li>When the Navigation Bar conflicts with other design elements or branding.</li>
    </ul>
  </div>
    </div>
    <div className="divi" />
    <h2>{`Accessibility`}</h2>
    <p>{`If your Nav items lose meaning by being truncated, they might benefit from being styled to wrap their text instead. Although the full text is exposed in a tooltip, that tooltip is not accessible through the keyboard, touch, voice control, or other assistive tech without hover functionality. Other things to consider are:`}</p>
    <ul>
      <li parentName="ul">{`The Navigation Bar component should be fully accessible to all users, including those with disabilities.`}</li>
      <li parentName="ul">{`Use semantic HTML elements to ensure that the component can be understood by assistive technology.`}</li>
      <li parentName="ul">{`Provide clear and descriptive labels for the navigation links and dropdown menus.`}</li>
      <li parentName="ul">{`Use ARIA attributes to provide additional information to assistive technology.`}</li>
      <li parentName="ul">{`Ensure that there is sufficient color contrast between the text and background to make it easy to read, especially for users with low vision.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use a same design or labelling for all of navigation links. So it looks consistent and easy to understand the function of the navigation links." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/navbar/guideline-navbar-a11y-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use a different  design or labelling for all of navigation links. So it looks inconsistent and confusing to understand the function of the navigation links." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/navbar/guideline-navbar-a11y-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>
    <div className="divi" />
    <h2>{`Content`}</h2>
    <p>{`When creating Navigation Bar :`}</p>
    <ul>
      <li parentName="ul">{`The Navigation Bar should provide clear and concise labels for each navigation link and dropdown menu.`}</li>
      <li parentName="ul">{`Use appropriate language and tone for the content of the navigation links and dropdown menus.`}</li>
      <li parentName="ul">{`Provide a way for users to search for content within the website or application, if appropriate.`}</li>
      <li parentName="ul">{`Include any relevant links or additional information that can help the user navigate the website or application.`}</li>
    </ul>
    <DoDontRow mdxType="DoDontRow">
      <DoDont type="do" captionTitle="Do" caption="Use a same design or labelling for all of navigation links. So it looks consistent and easy to understand the function of the navigation links." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/navbar/guideline-navbar-content-do.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
      <DoDont type="dont" captionTitle="Don't" caption="Use a different  design or labelling for all of navigation links. So it looks inconsistent and confusing to understand the function of the navigation links." aspectRatio="4:3" colLg="6" mdxType="DoDont">
        <img {...{
          "src": "https://legion-legion-ui-images-public-stage.oss-ap-southeast-5.aliyuncs.com/legion/component/navbar/guideline-navbar-content-dont.svg",
          "alt": "Alt text"
        }}></img>
      </DoDont>
    </DoDontRow>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      